import React, {useEffect, useState} from "react";
import {Link, Navigate, NavLink} from "react-router-dom";
import {Sidebar} from "./Sidebar";
import {Loading} from "./Loading";

export function Header() {
    let links = [
        "Inicio#/incio",
        "Ortografía#/ortografia",
        "Mapa#/mapa",
        "Enlaces#/enlaces",
        "Contacto#/contacto",
    ].map((e) => e.split("#"))

    let [isMenuOpen, setIsMenuOpen] = useState(false)
    let [isSearchOpen, setIsSearchOpen] = useState(false)

    function onMenuChange(value: boolean) {
        setIsMenuOpen(value);
        if (value) setIsSearchOpen(false);
    }

    function onSearchChange(value: boolean) {
        setIsSearchOpen(value);
        if (value) setIsMenuOpen(false);
    }

    const [data, setData] = useState<{
        data: { words: any[], categories: any[], families: any[] }
    } | null>(null)

    useEffect(() => {
        let url: string = process.env.REACT_APP_API_URL! + "data.json"
        fetch(url)
            .then((value) => value.json())
            .then((data) => setData(data))
    }, [])

    if (data == null) {
        return (<Loading/>)
    }

    function CloseModalButton(props: { color: string }) {
        return <div
            className={"right-5 fixed hover:bg-gray-200 hover:text-gray-600 p-2 rounded rounded-lg " + props.color}
            onClick={() => {
                setIsSearchOpen(false)
                setIsMenuOpen(false)
            }}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                 stroke="currentColor" className="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"/>
            </svg>
        </div>;
    }

    return <>
        <header className="sticky top-0 bg-white">
            <nav className="flex items-center flex-wrap bg-amber-600 p-6 h-20">
                <Brand/>
                <Search onClick={() => onSearchChange(!isSearchOpen)}/>
                <NavButton onClick={() => onMenuChange(!isMenuOpen)}/>
                <div className="w-full block flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block">
                    <div className="ml-5 text-sm lg:flex-grow">
                        {links.map((e) =>
                            <HeaderLink key={e[0]} href={e[1]} name={e[0]}/>
                        )}
                    </div>
                </div>
            </nav>
        </header>

        <div
            className={((isSearchOpen) ? "" : "hidden ") +
                "fixed bg-gray-100 top-20 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal h-full"}>
            <CloseModalButton color="text-gray-600"/>
            <Sidebar categories={data!.data.categories} families={data!.data.families} onNavigate={() => {
                onMenuChange(false)
                onSearchChange(false)
            }
            }/>
        </div>

        <div
            className={"bg-orange-400 py-4 fixed top-20 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal h-full " + (isMenuOpen ? "block" : "hidden")}>
            <CloseModalButton color="text-white"/>
            <div className="ml-5 text-sm lg:flex-grow">
                {links.map((e) =>
                    <HeaderLink key={e[0]} href={e[1]} name={e[0]}/>
                )}
            </div>
        </div>
    </>

    function HeaderLink(props: { href: string, name: string }) {
        let linkClasses = " block lg:inline-block lg:mt-0 hover:text-white mr-4 py-3 md:py-0"
        return <NavLink to={props.href}
                        onClick={(event) => {
                            onMenuChange(false)
                            onSearchChange(false)
                            Navigate({to: props.href})
                            event.preventDefault()
                        }}
                        className={({isActive}) => {
                            return (isActive ? "text-white text-bold" : "text-amber-50") + linkClasses
                        }}>
            {props.name}
        </NavLink>
    }
}

function Search(props: { onClick: () => void }) {
    return <button data-collapse-toggle="navbar-default" type="button"
                   className="inline-flex items-center p-2 mr-2 text-sm text-white rounded-lg md:hidden hover:bg-orange-200 ring-2 ring-white"
                   aria-controls="navbar-default" aria-expanded="false"
                   onClick={() => props.onClick()}>
        <span className="sr-only">Open main menu</span>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
             stroke="currentColor" className="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"/>
        </svg>
    </button>
}

function NavButton(props: { onClick: () => void }) {
    return <button data-collapse-toggle="navbar-default" type="button"
                   className="inline-flex items-center p-2 ml-3 text-sm text-white rounded-lg md:hidden hover:bg-orange-200 ring-2 ring-white"
                   aria-controls="navbar-default" aria-expanded="false"
                   onClick={() => props.onClick()}
    >
        <span className="sr-only">Open main menu</span>
        <svg className="w-6 h-6 fill-white" aria-hidden="true" viewBox="0 0 20 20"
             xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clip-rule="evenodd"></path>
        </svg>
    </button>
}

function Brand() {
    return <Link className="flex flex-1 items-center flex-shrink-0 text-white mr-6" to="/">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="40"
            viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g fill="#ffffff" fillRule="nonzero">
                    <path
                        d="M18 2a2.5 2.5 0 012.5 2.5v14.25a.75.75 0 01-.75.75H5.5a1 1 0 001 1h13.25a.75.75 0 010 1.5H6.5A2.5 2.5 0 014 19.5v-15A2.5 2.5 0 016.5 2H18zm0 1.5H6.5a1 1 0 00-1 1V18H19V4.5a1 1 0 00-1-1zM16 5a1 1 0 011 1v2a1 1 0 01-1 1H8a1 1 0 01-1-1V6a1 1 0 011-1h8zm-.5 1.5h-7v1h7v-1z"></path>
                </g>
            </g>
        </svg>
        <span className="font-semibold text-xl tracking-tight ml-2">La Moraña</span>
    </Link>
}

export default Brand;
