import React, {useState} from "react";
import {useNavigate, useSearchParams} from "react-router-dom";

export function Sidebar(props: { categories: any[], families: any[], onNavigate?: () => void | null }) {

    let [searchParams, setSearchParams] = useSearchParams();

    let categories = props.categories
    let families = props.families

    let navigate = useNavigate();

    let [search, setSearch] = useState<string>("")

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        if (search != "") {
            if (props.onNavigate != null) props.onNavigate();
            navigate("/diccionario/buscar/" + search!.toLowerCase())
            setSearch("")
        }
        event.preventDefault()
    }


    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
        setSearch(event.target.value)
    }

    return <div className="w-64 p-4 mt-1 mr-4 ">
        <div className="text-xl pb-1 text-amber-500">Buscar</div>
        <form onSubmit={handleSubmit}>
            <input className="px-2 py-1 border-gray-400 border rounded-md text-gray-400"
                   value={search}
                   type="text"
                   onChange={handleChange}/>
        </form>

        <div className="pt-5 text-xl pb-1 text-amber-500">Categorías</div>
        {categories.map((e) => <div className="hover:underline cursor-pointer pl-2 text-gray-400">
            <div key={e.name} onClick={() => {
                if (props.onNavigate != null) props.onNavigate();
                navigate("/diccionario/cat/" + e.id);
                // navigate("/diccionario/cat/" + e.id + "/" + props.letter);
                // setSearchParams({cat: e.id})
            }}>{e.name}</div>
        </div>)}

        <div className="pt-5 text-xl pb-1 text-amber-500">Familias</div>
        {families.map((e) => <div className="hover:underline cursor-pointer pl-2 text-gray-400">
            <div key={e.name} onClick={() => {
                if (props.onNavigate != null) props.onNavigate();
                navigate("/diccionario/fam/" + e.id);
                // navigate("/diccionario/fam/" + e.id + "/" + props.letter);
                // setSearchParams({fam: e.id})
            }}>{e.name}</div>
        </div>)}
    </div>
}
