import {WordCard} from "../components/WordCard";
import React, {useEffect, useState} from "react";
import {Loading} from "../components/Loading";
import {useParams} from "react-router-dom";
import {Sidebar} from "../components/Sidebar";
import {Home} from "../components/Home";

export function Dictionary() {
    let {category, family, search} = useParams()

    // let params = new URLSearchParams(document.location.search)
    // let category = params.get("cat")
    // let family = params.get("fam")

    const [data, setData] = useState<{
        data: { words: any[], categories: any[], families: any[] }
    } | null>(null)

    useEffect(() => {
        let url: string = process.env.REACT_APP_API_URL! + "data.json"
        fetch(url)
            .then((value) => value.json())
            .then((data) => setData(data))
    }, [])

    if (data == null) {
        return (<Loading/>)
    }


    let categories = data.data.categories
    let families = data.data.families

    let words = data.data.words.sort((n1, n2) => {
        return n1.title.localeCompare(n2.title)
    })
        .filter((e) => (family === undefined || e.family.toString() === family.toString()))
        .filter((e) => (category === undefined || e.category.toString() === category.toString()))
        .filter((e) => (search === undefined || e.title.toLowerCase().startsWith(search.toLowerCase())))
    // .filter((e) => (e.image != null))
    // .filter((e) => (e.title.toUpperCase().startsWith(letter!.toUpperCase())))


    return <>
        <div className="flex">
            <div className="hidden lg:block">
                <Sidebar categories={categories} families={families}/>
            </div>
            {(family === undefined && category === undefined && (search === undefined || search == "" || search == null)) ?
                <Home></Home> :
                <div>
                    <div className="text-xl px-5 pt-5 text-amber-500 hidden">Buscar</div>
                    {/*<Letters selected={letter}/>*/}
                    {
                        (words.length === 0 && (category !== null || family !== null)) ?
                            <div className="mx-auto max-w-3xl">
                                <div className="m-4 p-4 border border-gray-400 rounded-md ">
                                    <p>No se han encontrado resultados para esta búsqueda</p>
                                </div>
                            </div>
                            :
                            <div className="mx-auto max-w-3xl">
                                {words.map((value) => <>
                                        <WordCard word={value} categories={categories} families={families}/>
                                    </>
                                )
                                }
                            </div>
                    }</div>
            }
        </div>
    </>
}
