import data from '../data/map.json'
import {CSSProperties, useEffect, useState} from "react";

export function InteractiveMap() {

    const [mousePos, setMousePos] = useState({x: 0, y: 0});
    const [townImage, setTownImage] = useState<string | null>(null);
    const [selectedTown, setSelectedTown] = useState<string | null>(null);

    useEffect(() => {
        const handleMouseMove = (event: any) => {
            setMousePos({x: event.clientX, y: event.clientY});
        };

        window.addEventListener('mousemove', handleMouseMove);

        return () => {
            window.removeEventListener(
                'mousemove',
                handleMouseMove
            );
        };
    }, []);

    function onMouseOut() {
        setTownImage(null)

    }

    function onMouseOver(href: string) {
        setTownImage(href)
    }

    let y = mousePos.y + 20;
    let x = mousePos.x + 20;
    let imagePosition: CSSProperties = {
        position: "absolute",
        top: y.toString() + "px",
        left: x.toString() + "px"
    }

    function onClick(href: string) {
        setSelectedTown(href)
    }


    function TownImageModal(props: { href: string }) {
        return <>
            <div className="z-20 absolute inset-6" onClick={() => setSelectedTown(null)}>
                <div className="inset-1">
                    <div className="z-20 bg-black" >
                        <button
                            onClick={() => setSelectedTown(null)}
                            type="button"
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
                            data-modal-hide="defaultModal">
                            <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd"
                                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                      clipRule="evenodd"/>
                            </svg>
                            <span className="">Cerrar</span>
                        </button>
                    </div>
                </div>
                <img alt="town" src={"legacy/imagenes_pueblos/" + props.href}/>
            </div>

            <div className="z-10 opacity-70 fixed bg-black
        top-0 left-0 w-full h-full overflow-x-hidden overflow-y-auto"
                 onClick={() => setSelectedTown(null)}>

            </div>

        </>
    }


    return <div className="p-10">

        {selectedTown ?
            <TownImageModal href={selectedTown}></TownImageModal> : <></>
        }

        {townImage ?
            <img alt="town" src={"legacy/thumb_pueblos/" + townImage} style={imagePosition}/> : <></>
        }
        <img alt="town" src="map/map.jpg" width="700" height="656" useMap="#Map" onMouseOut={onMouseOut}/>
        <map name="Map">
            {data.map((e) => <area
                alt={e.href}
                key={e.coords}
                onMouseOver={() => onMouseOver(e.href)}
                onMouseOut={onMouseOut}
                shape={e.shape}
                coords={e.coords}
                onClick={() => onClick(e.href)}
                // href={"/public/legacy/imagenes_pueblos/" + e.href}
            ></area>)}
        </map>
    </div>
}
