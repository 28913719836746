import {Proverb} from "./Proverb";
import React from "react";

export function Home() {
    let blocks = [
        "Más de 4.000 palabras tradicionales de La Moraña (Ávila), y comparación con el Diccionario de la RAE.",
        "Significado del nombre de los 87 pueblos de La Moraña, con 184 fotos de todos ellos.",
        "Actualización diaria de la fecha, hora, temperatura, y hora de salida y puesta de sol y de la luna.",
        "Refranes diarios relacionados con los meses del año o los pueblos enclavados en La Moraña.",
        "Cerca de 1.000 fotografías de objetos, arreos, animales, campo, muebles, bodega, matanza...",
        "Lengua Española, con ortografía, sinónimos, conjugación de verbos y diccionario de la Real Academia.",
        "Enlaces a Academias de la Lengua Española, Instituto Cervantes en el mundo, diccionarios y editoriales",
    ]

    let images = [
        "https://diccionario.onepointzero.org/wp/wp-content/uploads/2023/01/aguilucho.jpg",
        "https://diccionario.onepointzero.org/wp/wp-content/uploads/2023/01/maza.jpg",
        "https://diccionario.onepointzero.org/wp/wp-content/uploads/2023/01/roseta.jpg",
        "https://diccionario.onepointzero.org/wp/wp-content/uploads/2023/01/aguedas.jpg",
        "https://diccionario.onepointzero.org/wp/wp-content/uploads/2023/01/enciclopedia.jpg",
        "https://diccionario.onepointzero.org/wp/wp-content/uploads/2023/01/zarza.jpg",
        "https://diccionario.onepointzero.org/wp/wp-content/uploads/2023/01/acerico.jpg",
    ]

    let data = [];
    for (let i = 0; i < blocks.length; i++) {
        data[i] = {"text": blocks[i], "image": images[i]}
    }

    return <div>
        <Proverb/>
        <div className="container max-w-3xl mx-auto grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            {data.map((block) =>
                <div className="m-2 border-gray-400 border rounded-md flex md:block">
                    <div className="bg-gray-100 bottom-0">
                        <img className="md:aspect-video w-full object-cover "
                             src={block.image}
                             alt="Imagen"/></div>

                    <div className="p-5">{block.text}</div>
                </div>
            )}
        </div>
    </div>
}
