import React from "react";

export function WordCard(params: { word: any, categories: any[], families: any[] }) {

    let category = params.categories.filter((value) => value.id === params.word.category)[0];
    let family = params.families.filter((value) => value.id === params.word.family)[0];

    let raeUrl = "https://dle.rae.es/" + params.word.title.split(" ")[0];
    return <div className="md:flex gap-4 m-4 border border-gray-400 rounded-md">
        <div className="flex-1 p-4">
            <h4 className="text-xl font-semibold mb-4">{params.word.title}</h4>
            <p className="text-xs text-amber-500 my-2">
                [<span>{family.name}</span>]
                [<span>{category.name}</span>]
            </p>
            <p>{params.word.definition}</p>
            {params.word.drae ?
                <p className="mt-2 text-gray-400 italic">
                    {params.word.drae}
                    <a target="_blank"
                       className="text-xs text-amber-500 mt-2 underline" href={raeUrl}
                    ><br/>Ver en la RAE</a>
                </p> : <></>}

        </div>
        {params.word.image ?
            <div className="md:flex-initial md:p-0 p-3 bg-gray-100">
                <img loading="lazy" className="" src={params.word.image} alt={params.word.title}/>
            </div>
            :
            <></>
        }

    </div>
}
