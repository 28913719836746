import React, {useState} from "react";

var customData = require('../data/orthography.txt');

export function Orthography() {

    const [text, setText] = useState<string>("");
    fetch(customData)
        .then((response) => response.text())
        .then((textContent) => {
            setText(textContent);
        });

    return <div className="p-5">
        <div className="pb-5 text-3xl pb-1 text-amber-500">Ortografía</div>
        <div className="content" dangerouslySetInnerHTML={{__html: text}}></div>
    </div>
}
