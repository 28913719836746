import React, {useEffect, useState} from "react";

export function Proverb() {
    const [proverb, setProverb] = useState<string | null>(null)

    function padWithLeadingZeros(num:number, totalLength:number) {
        return String(num).padStart(totalLength, '0')
    }

    let today = new Date()
    let dayNum = today.getDate()
    let month = padWithLeadingZeros(today.getMonth() + 1, 2)

    useEffect(() => {
        let url: string = process.env.REACT_APP_API_URL! + "proverbs/" + month + ".json"
        fetch(url)
            .then((value) => value.json())
            .then((data) => setProverb(data[dayNum - 1]))
    }, [])

    return <>
        <div className="flex max-w-3xl mx-2 mb-5 mt-5 bg-gray-200 py-2 px-4 text-gray-600 italic rounded rounded-md">
            <svg className="fill-gray-500" width="35" height="30" viewBox="0 0 7 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.188 1.855H1.178C1.407 1.373 1.895 1.036 2.463 1.036C2.75 1.036 2.982 0.804 2.982 0.518C2.981 0.232 2.749 0 2.462 0C1.104 0 0 1.104 0 2.463C0 2.484 0.0100001 2.503 0.0120001 2.524C0.0100001 2.545 0 2.563 0 2.584V4.042C0 4.444 0.327 4.771 0.729 4.771H2.187C2.589 4.771 2.916 4.445 2.916 4.042V2.584C2.917 2.181 2.59 1.855 2.188 1.855Z"/>
                <path d="M5.64403 1.855H4.63503C4.86303 1.374 5.35203 1.036 5.92003 1.036C6.20703 1.036 6.43803 0.805 6.43803 0.518C6.43803 0.232 6.20603 0 5.91903 0C4.56203 0 3.45703 1.104 3.45703 2.462C3.45703 2.484 3.46703 2.503 3.46903 2.523C3.46703 2.545 3.45703 2.563 3.45703 2.584V4.042C3.45703 4.445 3.78403 4.771 4.18603 4.771H5.64403C6.04703 4.771 6.37303 4.445 6.37303 4.042V2.584C6.37403 2.181 6.04703 1.855 5.64403 1.855Z"/>
            </svg>
            <div className="flex-1 mx-4">{proverb}</div>
            <svg className="fill-gray-500" width="35" height="30" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.81158 3.14598H5.82158C5.59258 3.62798 5.10458 3.96498 4.53658 3.96498C4.24958 3.96498 4.01758 4.19598 4.01758 4.48298C4.01758 4.76998 4.24958 5.00198 4.53758 5.00198C5.89558 5.00198 6.99958 3.89798 6.99958 2.53898C6.99958 2.51798 6.98958 2.49898 6.98758 2.47798C6.98958 2.45698 6.99958 2.43898 6.99958 2.41698V0.958981C6.99958 0.557981 6.67258 0.22998 6.27058 0.22998H4.81258C4.41058 0.22998 4.08358 0.555981 4.08358 0.958981V2.41698C4.08258 2.81898 4.40958 3.14598 4.81158 3.14598Z"/>
                <path d="M1.3555 3.14598H2.3655C2.1365 3.62698 1.6485 3.96498 1.0805 3.96498C0.793501 3.96498 0.5625 4.19498 0.5625 4.48298C0.5625 4.76898 0.7935 5.00198 1.0815 5.00198C2.4375 5.00198 3.5435 3.89798 3.5435 2.53998C3.5435 2.51898 3.5335 2.49898 3.5305 2.47798C3.5335 2.45698 3.5435 2.43898 3.5435 2.41698V0.958981C3.5435 0.555981 3.2165 0.22998 2.8145 0.22998H1.3555C0.9535 0.22998 0.6265 0.555981 0.6265 0.958981V2.41698C0.6265 2.81898 0.9535 3.14598 1.3555 3.14598Z"/>
            </svg>
        </div>
    </>
}
