import React, {useEffect} from 'react';
import './App.css';
import {Header} from "./components/Header";
import {Dictionary} from "./pages/Dictionary";
import {Footer} from "./components/Footer";
import {Route, Routes, useLocation} from "react-router-dom";
import {InteractiveMap} from "./pages/InteractiveMap";
import {Orthography} from "./pages/Orthography";
import {Links} from "./pages/Links";
import {Contact} from "./pages/Contact";
import {StaticPage} from "./pages/StaticPage";
import {QueryClient, QueryClientProvider} from "react-query";
import {UnderConstruction} from "./components/UnderConstruction";
function App() {

    useEffect(() => {
        document.title = "La Moraña";
    }, []);

    const queryClient = new QueryClient()
    const location = useLocation();
    const showHeaderAndFooter = location.pathname !== '/';

    return (
        <QueryClientProvider client={queryClient}>
            {showHeaderAndFooter && <Header />}
            <Routes>
                <Route path="/" element={<UnderConstruction/>}></Route>
                <Route path="/inicio" element={<Dictionary/>}></Route>
                <Route path="/diccionario/cat/:category/" element={<Dictionary/>}>
                    {/*<Route path=":letter" element={<Dictionary/>}/>*/}
                </Route>
                <Route path="/diccionario/fam/:family/" element={<Dictionary/>}>
                    {/*<Route path=":letter" element={<Dictionary/>}/>*/}
                </Route>
                <Route path="/diccionario/buscar/:search" element={<Dictionary/>}/>
                <Route path="/ortografia" element={<Orthography/>}/>
                <Route path="/mapa" element={<InteractiveMap/>}/>
                <Route path="/enlaces" element={<Links/>}/>
                <Route path="/contacto" element={<Contact/>}/>
                <Route path="/privacidad"
                       element={<StaticPage title="Política de privacidad y aviso legal" name="legal_page"/>}/>
                <Route path="/cookies" element={<StaticPage title="Política de cookies" name="cookies_page"/>}/>
            </Routes>
            {showHeaderAndFooter && <Header />}
        </QueryClientProvider>
    );
}

export default App;
