import React, {useEffect, useState} from "react";
import {Loading} from "../components/Loading";

export function Contact() {

    const [data, setData] = useState<string|null>(null)

    useEffect(() => {
        let url: string = process.env.REACT_APP_API_URL! + "pages/contact_page.html"
        fetch(url)
            .then((value) => value.text())
            .then((data) => setData(data))
    }, [])

    if (data == null) {
        return <Loading></Loading>
    }

    return <div className="lm-static-page px-10 py-5">
        <div className="pb-5 text-3xl pb-1 text-amber-500">Contacto</div>
        <div  dangerouslySetInnerHTML={{__html: data}}></div>
    </div>
}
