import React, {useState} from "react";
import {Loading} from "../components/Loading";
import {useQuery} from "react-query";
import {ErrorMessage} from "../components/ErrorMessage";

const getLinks = async (): Promise<any> => {
    let url: string = process.env.REACT_APP_API_URL! + "links.json"
    return fetch(url).then((value) => value.json())
}

export function Links() {

    const [selected, setSelected] = useState<number | null>(null)
    const query = useQuery<{ data: { links: any[], categories: any[] } }>('links', getLinks)

    if (query.isFetching) {
        return <Loading/>
    }

    if (query.data == null) {
        return <ErrorMessage>Lo sentimos. No hay información disponible en estos momentos.</ErrorMessage>
    }

    if (selected === null) {
        setSelected(query.data!.data.categories[0].id)
        return <Loading/>
    }

    let category = query.data!.data.categories.filter((e) => e.id == selected)[0]

    let links = query.data!.data.links.filter((e) => e.category == category.id)

    return <div className="md:flex p-4 mt-1 mr-4">
        <LinkCategories categories={query.data!.data.categories}
                        selected={selected!}
                        onSelect={(id) => setSelected(id)}/>
        <div>
            <div className="text-xl pb-1 text-amber-500 mb-2 hidden sm:hidden md:block">{category.name}</div>
            {links.map((e) => <LinkInfo title={e.title} url={e.url}/>)}
        </div>
    </div>
}

function LinkInfo(props: { title: string, url: string }) {
    return <div className="mb-5 p-4 border border-gray-400 rounded-md">
        <div>{props.title}</div>
        <div className="text-sm text-amber-500 hover:underline">
            <a target="_blank" href={props.url}>Visitar enlace</a>
        </div>
    </div>
}

function LinkCategories(props: { categories: any[], selected: number, onSelect: (id: number) => void }) {
    return <div>
        <div className="text-xl pb-1 text-amber-500">
            <span className="hidden sm:hidden md:block">Categorías</span>
            <select className="block md:hidden mb-4" onChange={(e) => props.onSelect(parseInt(e.target.value, 10))}>
                {props.categories.map((e) =>
                    <option value={e.id} className="hover:underline cursor-pointer pl-2 text-gray-400 my-3">
                        {e.name}
                    </option>)}
            </select>

        </div>
        <div className="hidden md:w-64 sm:hidden md:block">
            {props.categories.map((e) =>
                <div className="hover:underline cursor-pointer pl-2 text-gray-400 my-3"
                     onClick={() => props.onSelect(e.id)}
                >{e.name}</div>
            )}
        </div>
    </div>
}
