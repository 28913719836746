export function UnderConstruction() {
    return (
        <div className="bg-gray-100 min-h-screen pt-20">
            <div className="max-w-4xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
                <div className="text-center">
                    <h1 className="text-4xl font-extrabold text-gray-900">Diccionario de la Moraña</h1>
                    <p className="mt-3 text-lg text-gray-500">¡Gracias por tu paciencia! ¡Nuestro sitio web estará en línea pronto!</p>
                </div>
            </div>
        </div>
    )
}